<template>
  <section class="Edit-Content">
    <Loader :loading="showLoader" />
    <div class="page-header mb-3">
      <h3 class="page-title">Edit Content</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/content-page/content-page" class="text-primary">Content
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit Content
          </li>
        </ol>
      </nav>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">

          <div class="card-body">
            <form @submit.prevent="editContentPage">
              <div class="row" v-if="editContent">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" v-model="editContent.name" id="name" name="name" class="form-control" :class="{
                      'is-invalid': submitted && $v.editContent.name.$error,
                    }" />
                    <div v-if="submitted && !$v.editContent.name.required" class="invalid-feedback">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="title">Title</label>
                    <input type="text" v-model="editContent.title" id="title" name="title" class="form-control" :class="{
                      'is-invalid': submitted && $v.editContent.title.$error,
                    }" />
                    <div v-if="submitted && !$v.editContent.title.required" class="invalid-feedback">
                      Title is required
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="content">Content</label>
                    <vue-editor v-model="editContent.content" id="content" name="content" use-custom-image-handler
                      @image-added="handleImageAdded" :class="{
                        'is-invalid':
                          submitted && $v.editContent.content.$error,
                      }"></vue-editor>
                    <div v-if="submitted && !$v.editContent.content.required" class="invalid-feedback">
                      Content is required
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group float-right">
                    <button class="btn btn btn-add btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";
import { contentApi } from "../../api";
import Loader from "../../components/Loader";
const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  name: "edit-content",
  components: {
    VueEditor,
    Loader,
  },

  data() {
    return {
      editContent: {
        name: "",
        title: "",
        content: "",
      },
      submitted: false,
      content: "",

      showLoader: false,
    };
  },
  validations: {
    editContent: {
      name: { required },
      title: { required },
      content: { required },
    },
  },
  mounted() {
    this.listById();
  },
  methods: {
    async listById() {
      this.showLoader = true;
      const { data } = await contentApi.listById(this.$route.params.id);
      let aaa = data.data.data;
      aaa.forEach((value, index) => {
        this.editContent = value;
      });
      this.showLoader = false;
    },

    async editContentPage() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.showLoader = true;
      const data = await contentApi.updateContent(this.$route.params.id, {
        ...this.editContent,
      });
      this.showLoader = false;
      this.$router.push("/content-page/content-page");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      let pages = "pages";
      formData.append("file", file);
      formData.append("folder", pages);
      axios({
        url: `${baseURL}/api/media/file/upload`,
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = result.data.data.path; // Get url from response
          url = `${baseURL}/public/${url}`;
          Editor.insertEmbed(cursorLocation, "image", url);

          resetUploader();
        })
        .catch((err) => {
          alert("image not uploaded " + err);
        });
    },
  },
};
</script>