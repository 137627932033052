var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"Edit-Content"},[_c('Loader',{attrs:{"loading":_vm.showLoader}}),_c('div',{staticClass:"page-header mb-3"},[_c('h3',{staticClass:"page-title"},[_vm._v("Edit Content")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb p-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/content-page/content-page"}},[_vm._v("Content ")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" Edit Content ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.editContentPage.apply(null, arguments)}}},[(_vm.editContent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editContent.name),expression:"editContent.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.editContent.name.$error,
                  },attrs:{"type":"text","id":"name","name":"name"},domProps:{"value":(_vm.editContent.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editContent, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.editContent.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editContent.title),expression:"editContent.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.editContent.title.$error,
                  },attrs:{"type":"text","id":"title","name":"title"},domProps:{"value":(_vm.editContent.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editContent, "title", $event.target.value)}}}),(_vm.submitted && !_vm.$v.editContent.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title is required ")]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"content"}},[_vm._v("Content")]),_c('vue-editor',{class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.editContent.content.$error,
                    },attrs:{"id":"content","name":"content","use-custom-image-handler":""},on:{"image-added":_vm.handleImageAdded},model:{value:(_vm.editContent.content),callback:function ($$v) {_vm.$set(_vm.editContent, "content", $$v)},expression:"editContent.content"}}),(_vm.submitted && !_vm.$v.editContent.content.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Content is required ")]):_vm._e()],1)]),_vm._m(0)]):_vm._e()])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group float-right"},[_c('button',{staticClass:"btn btn btn-add btn-primary"},[_vm._v("Update")])])])
}]

export { render, staticRenderFns }